import React from "react";
import {
	BlueSquares1,
	BlueSquares2,
	PinkCircles0,
	PinkCircles1,
} from "@assets/Background";
import { Link, PageLayout, TrustedBy } from "@components";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import { StaticImage } from "gatsby-plugin-image";
import { pinkText } from "@components/Typography/Typography";
import ImageAndBulletPoints from "@components/ImageAndBulletPoints/ImageAndBulletPoints";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";
import Button, {
	ArrowLinkButton,
	GetStartedButton,
} from "@components/Button_new/Button";
import TryGenemodForFree from "@components/TryGenemodForFree/Index";
import { StandardizeResearchData } from "@components/StandardizeResearchData/StandardizeResearchData";
import * as styles from "../../CommonResourcesOverview.module.scss";

export default function Academia() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "academic-labs" }}
		>
			<StartForFree />
			<EntireDepartment />
			<AffordablePlans />
			<TryGenemodForFree withSquares />
			<TrustedBy backgroundType="solidLightPink" />
			<StandardizeResearchData />
		</PageLayout>
	);
}

const StartForFree = () => (
	<BackgroundFadeContainer type="blueFade" skewBottom>
		<BackgroundShapes
			leftShape={{ shape: <BlueSquares1 />, xOffset: -50 }}
			rightShape={{
				shape: <BlueSquares2 />,
				yOffset: 200,
			}}
		>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<SectionTitle
					title="Start for free – upgrade anytime"
					subtitle="We bring your team’s work together in one shared space. Improve process management by leaving the repetitive stuff to us so you can do more of the work you do best."
					textColor="ui-01"
					titleWidth={860}
					subtitleWidth={736}
					marginTop={163}
					marginTopTablet={186}
					marginTopMobile={146}
				/>
				<Button link="APPLICATION_ACADEMIA" target="_blank">
					Apply now
				</Button>
				<StaticImage
					loading="eager"
					src="../../../assets/products/Freezer-rack-large.png"
					alt="freezer rack example page"
					className={styles.fullWidthImage}
				/>
			</div>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const AffordablePlans = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes
			rightShape={{ shape: <PinkCircles1 />, yOffset: 340 }}
		>
			<SectionTitle
				title={pinkText`We have the most ${"affordable"} paid plans you’ll find (the same cost as a latte a week)`}
				textColor="text-dark"
				titleWidth={1094}
				marginBottom={60}
				marginBottomTablet={60}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "Fully customizable - from shelves to racks and cryoboxes",
						text: "Custom design a virtual freezer that matches its real-life counterpart, shelf for shelf and item for item.",
					},
					{
						title: pinkText`Generate and manage ${"inventory alerts"} for 100+ use cases`,
						text: "Create alerts for when consumables reach a certain stock level or calculate inventory cost and fluctuation.",
					},
					{
						title: "Genemod Notebook takes R&D collaboration to a whole other level",
						text: "Create flexible and editable protocol templates, add attachments to experiments, and build Excel-like tables within entries.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/Freezer_box.png"
						alt="freezer box example page"
						width={786}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);

const EntireDepartment = () => (
	<BackgroundFadeContainer type="pinkFade" skewTop skewBottom>
		<BackgroundShapes leftShape={{ shape: <PinkCircles0 /> }}>
			<SectionTitle
				title={pinkText`The modern R&D platform where your ${"entire department"} is aboard`}
				textColor="text-dark"
				titleWidth={700}
				marginBottom={60}
				marginBottomTablet={12}
				marginBottomMobile={12}
			/>
			<ImageAndBulletPoints
				bullets={[
					{
						title: "We offer site-wide discounts for departments and cores",
						text: (
							<>
								{
									"Ask your department if they offer Genemod as a preferred software vendor."
								}{" "}
								<div style={{ marginTop: 20 }}>
									<ArrowLinkButton
										color="marine"
										link="CONTACT_US"
										state={{
											defaultSubject: "Billing",
										}}
									>
										Contact us
									</ArrowLinkButton>
								</div>
							</>
						),
					},
					{
						title: "Finally, an ELN built for scientists",
						text: "It’s never been easier to cycle across all your experiments, protocols, and attachments in one place.",
					},
					{
						title: "A source of truth for your inventory",
						text: "From reagents to custom item types - we customize to match exactly how it is in the lab.",
					},
				]}
				image={
					<StaticImage
						loading="eager"
						src="../../../assets/products/exp_editor.png"
						alt="screenshot of genemods lab notebook software"
						width={786}
						height={586}
					/>
				}
				withImageShadowMargins
				bulletStyles={{
					titleVariant: "HEADER4",
					titleColor: "text-dark",
					textVariant: "SUBHEADERDESC",
					textColor: "text-dark",
					containerWidth: 476,
				}}
				imagePosition="left"
			/>
		</BackgroundShapes>
	</BackgroundFadeContainer>
);
